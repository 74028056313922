/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/

@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Regular.woff2') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face { 
  font-family: "Roboto"; 
  src: url('../fonts/Roboto-Regular.woff2') format('truetype');
  font-style: normal; 
} 

@font-face { 
  font-family: "Inter"; 
  src: url('../fonts/Inter-Regular.woff2') format('truetype');
  font-style: normal; 
} 